import { graphql, navigate, PageProps, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import noop from 'lodash/noop'
import React, { useState } from 'react'
import { Check } from 'react-feather'
import styled, { useTheme } from 'styled-components'
import { Button } from '../../../components/Button'
import Container from '../../../components/Container'
import Form from '../../../components/Form'
import PDFViewer from '../../../components/PDFViewer'
import { Site } from '../../../components/Site'
import { SEO } from '../../../SEO'

const facts = [
  'Australian based with strong understanding of local industry requirements',
  'Fully cloud based SaaS solution',
  'Analysis of 100% of conversational interactions',
  'Reduce Complaints and Compliance Risk',
  'Improve CX',
  'Improve sales yield'
]

type Pdf = 'aboutUx' | 'aboutDaisee' | 'aboutEssence' | 'aboutPredictors'
const pdfs: Pdf[] = ['aboutDaisee', 'aboutUx', 'aboutEssence', 'aboutPredictors']

const GSummit: React.FC<PageProps> = props => {
  const themeContext = useTheme()
  const [activePdf, setActivePdf] = useState<Pdf>()

  const data = useStaticQuery(graphql`
    query Gsummit {
      hero: file(relativePath: { eq: "daisee-g-summit-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bold: file(relativePath: { eq: "daisee-bold-new.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      form: file(relativePath: { eq: "event-g-summit-no-date.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      product: allFile(filter: { relativeDirectory: { eq: "product" } }, sort: { fields: name }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      teamView: file(relativePath: { eq: "ui/ui-teamView.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutUx: file(relativePath: { eq: "daisee-about-ux.pdf" }) {
        publicURL
      }
      aboutDaisee: file(relativePath: { eq: "daisee-about.pdf" }) {
        publicURL
      }
      aboutEssence: file(relativePath: { eq: "daisee-get-to-know-Essence.pdf" }) {
        publicURL
      }
      aboutPredictors: file(relativePath: { eq: "daisee-get-to-know-Predictors.pdf" }) {
        publicURL
      }
    }
  `)

  return (
    <Site seo={SEO.startDemo} {...props}>
      <Container>
        <div style={{ marginTop: '6rem' }}>
          <Img
            fluid={data.hero.childImageSharp.fluid}
            alt='daisee G-Summit'
            imgStyle={{ maxHeight: 400, objectFit: 'contain' }}
          />
          <Content>
            <H1 className='h1'>Your contact centre to the power of daisee AI</H1>
            <div className='grid mb'>
              <div>
                <h2 className='h2'>We are daisee.</h2>
                <p>
                  We are an Australian AI provider supporting leading contact centres to mine and use insights from
                  customer conversations to drive better compliance, efficiency and satisfaction outcomes. We believe
                  your customers are already telling you what they want. With the right combination of AI, machine
                  learning and behavioural science, you can draw out rich lessons to understand the topics customers are
                  calling about and predict business outcomes.
                </p>
              </div>
              <div>
                <Img
                  fluid={data.bold.childImageSharp.fluid}
                  alt='daisee A Bold New Way'
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            </div>
            <div className='grid'>
              <div>
                <h2 className='h2'>Unlock the value of your contact centre faster with daisee</h2>
                <p>
                  Our technology is unique in its class thanks to proprietary AI and more than 10 million hours of call
                  data built into the platform. With daisee, there’s no training language models from scratch for months
                  before starting to analyse data or deliver insights. daisee delivers a speed to value because much of
                  the hard work has already been done by our team in developing the product. In many cases, you can go
                  from deployment to analysis and recommendations in a matter of weeks.
                </p>
              </div>
              <Img fluid={data.teamView.childImageSharp.fluid} alt='daisee UI' imgStyle={{ objectFit: 'contain' }} />
            </div>
          </Content>
        </div>
      </Container>
      <BreakoutSection>
        <Stripe skew={8} offset={32} opacity={0.5} />
        <Stripe skew={7} offset={16} opacity={0.7} />
        <Stripe skew={6} />
      </BreakoutSection>
      <Container>
        <Content>
          <div>
            <p className='pull-out'>
              Turn challenges into opportunities with the robust AI and machine learning features of the daisee platform
              to drive greater empathy in your contact centre customer interactions
            </p>
          </div>
          <h2 className='h2'>Get to know the advanced features of the daisee platform</h2>
          <div className='products'>
            {data.product.edges.map((image: any, i: number) => (
              <div style={{ display: 'grid', gap: '0.5rem' }}>
                <Img fluid={image.node.childImageSharp.fluid} />
                <Button
                  action='See more'
                  btnHeight='auto'
                  bgColor={themeContext.colors.green}
                  onClick={() => setActivePdf(pdfs[i])}
                />
              </div>
            ))}
          </div>
        </Content>
        {!!activePdf && <PDFViewer pdfPath={data?.[activePdf]?.publicURL} onClose={() => setActivePdf(undefined)} />}
        <Img
          fluid={data.form.childImageSharp.fluid}
          alt='Genesys G-Summit'
          imgStyle={{ maxHeight: 300, objectFit: 'contain', objectPosition: 'center right' }}
          style={{ maxHeight: 300, background: '#ff8f14' }}
        />
        <StyledSignUp>
          <SignUpWrapper>
            <LeftPanel>
              <Summary>
                <SummaryHeading>Discover the power of daisee today</SummaryHeading>
                {facts.map(fact => (
                  <Row key={fact}>
                    <i>
                      <Check size={18} color={themeContext.colors.purple} />
                    </i>
                    <p>{fact}</p>
                  </Row>
                ))}
              </Summary>
            </LeftPanel>
            <RightPanel>
              <FormHeading>Contact daisee to get started today</FormHeading>
              <Form
                setSubmitted={noop}
                setUserName={noop}
                formName='g-summit'
                successMessage='Thank you, we will be in touch with you soon'
                errorMessage='Oops, something went wrong - please try again'
                callback={() => navigate('/thankyou')}
                action='Contact'
              />
            </RightPanel>
          </SignUpWrapper>
        </StyledSignUp>
      </Container>
    </Site>
  )
}

type StripeProps = { offset?: number; skew: number; opacity?: number }

const Stripe: React.FC<StripeProps> = ({ skew, offset, opacity = 1 }) => {
  return <_Stripe skew={skew} offset={offset} opacity={opacity} />
}

const _Stripe = styled.div<StripeProps>`
  background: ${({ theme }) => theme.colors.purple};
  position: absolute;
  width: 100%;
  height: 150px;
  transform-origin: 100% 0;
  transform: ${({ skew }) => `skewY(${skew}deg);`};
  opacity: ${({ opacity }) => opacity};
  margin-top: ${({ offset }) => offset && `-${offset}px`};
`

const BreakoutSection = styled.div`
  position: relative;
  margin: 16rem 0 8rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    margin: 7rem 0;
    padding-top: 3rem;
  }
`

const Content = styled.div`
  margin: 3rem 0;

  .h1 {
    margin: 2rem 0 3rem;
    max-width: 20ch;
  }

  .mb {
    margin-bottom: 3rem;
  }

  .pull-out {
    padding-top: 14rem;
    padding-bottom: 3rem;
    margin: 0;
    font-weight: 500;
    font-size: 3rem;
    letter-spacing: -1.5px;
    line-height: 1.1;
    color: ${({ theme }) => theme.colors.purple};

    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 2.5rem;
      letter-spacing: -1.25px;
      padding-top: 3rem;
    }
    ${({ theme }) => theme.breakpoints.mobile} {
      font-size: 1.75rem;
      letter-spacing: -1px;
    }
  }

  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;

    ${({ theme }) => theme.breakpoints.tablet} {
      gap: 2rem;
    }

    ${({ theme }) => theme.breakpoints.tablet} {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
    }
  }

  .h2 {
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.grey700};
    line-height: 1.3;
    ${({ theme }) => theme.breakpoints.mobile} {
      font-size: 1.25rem;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.6;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;

    ${({ theme }) => theme.breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  }
`

const H1 = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 3rem;
  letter-spacing: -1.5px;
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.purple};

  @media all and (max-width: 48em) {
    font-size: 2rem;
  }
`

const Summary = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

const Row = styled.article`
  display: flex;
  color: #fff;
  margin-bottom: 0.5rem;
  i {
    margin-right: 0.75rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 600;
  }
`

const SummaryHeading = styled.h2`
  margin: 0 0 1.5rem;
  font-size: 1.25rem;
  color: #fff;
`

const StyledSignUp = styled.section`
  position: relative;
  z-index: 1;
`

const SignUpWrapper = styled.div`
  display: grid;
  grid-template-columns: 38.2% 61.8%;
  width: 100%;
  height: 100.1vh;
  overflow: hidden;

  @media all and (max-width: 56.25em) {
    grid-template-columns: 1fr;
    height: auto;
  }
`

const LeftPanel = styled.div`
  background: #ff8f14;
  background-size: 400% 400%;
  width: 100%;
  height: 100%;
  transition: all 0.75s ease;
`

const FormHeading = styled.h1`
  margin: 2.5rem 0 3rem;
  letter-spacing: -0.5px;
  display: flex;
  position: relative;
  text-transform: capitalize;

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    width: 4rem;
    height: 4px;
    background: ${({ theme }) => theme.colors.purple};
  }

  svg {
    margin-left: 1rem;
  }
  @media all and (max-width: 36.625em) {
    font-size: 1.75rem;
    line-height: 1.5;
  }
`

const RightPanel = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background: #fff;
  position: relative;
  height: fit-content;

  @media all and (max-width: 56.25em) {
    grid-row: 1;
    padding-bottom: 3rem;
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0;
    padding-bottom: 3rem;
  }
`

export default GSummit
